import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import FirebaseContext from "./context/firebase";
import { app, db } from "./lib/firebase";
import "./styles/app.css";
import "react-loading-skeleton/dist/skeleton.css";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");

ReactDOM.createRoot(rootElement).render(
  <FirebaseContext.Provider value={{ app, db }}>
    <App />
  </FirebaseContext.Provider>
);

import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import React, { Suspense, lazy } from "react"; // Lets us load components dynamically. Split bundles into smaller chunks.
// If you use 'webpack', its real easy to do. But even without it, you can do lazy loading.
import * as ROUTES from "./constants/routes";
import useAuthListener from "./hooks/use-auth-listener";
import UserContext from "./context/user";
import ProtectedRoute from "./helpers/protected.route";

const LandingPage = lazy(() => import("./pages/coming-soon"));
const Login = lazy(() => import("./pages/login"));
const Signup = lazy(() => import("./pages/signup"));
const Dashboard = lazy(() => import("./pages/dashboard"));
const NotFound = lazy(() => import("./pages/not-found"));
const Profile = lazy(() => import("./pages/profile"));

export default function App() {
  const { user } = useAuthListener();

  const router = createBrowserRouter([
    {
      path: ROUTES.LANDING_PAGE,
      element: user ? <Navigate to={ROUTES.LANDING_PAGE} /> : <LandingPage />,
    },
    {
      path: ROUTES.LOGIN,
      element: user ? <Navigate to={ROUTES.DASHBOARD} /> : <Login />,
    },
    {
      path: ROUTES.SIGN_UP,
      element: user ? <Navigate to={ROUTES.DASHBOARD} /> : <Signup />,
    },
    {
      path: ROUTES.DASHBOARD,
      element: (
        <ProtectedRoute
          user={user}
          fallback={<Navigate to={ROUTES.LOGIN} replace />}
        >
          <Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.PROFILE,
      element: <Profile />,
    },
    {
      path: ROUTES.NOT_FOUND,
      element: <NotFound />,
    },
  ]);

  return (
    <UserContext.Provider value={{ user }}>
      <Suspense fallback={<p>Loading...</p>}>
        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
      </Suspense>
    </UserContext.Provider>
  );
}

import { createContext, Context } from "react";
import { FirebaseApp } from "firebase/app";
import { Firestore } from "firebase/firestore";

/**
 * FirebaseContext is a context created for managing global state related to Firebase.
 * The context is a part of the Context API in React. It allows us to avoid prop drilling
 * by providing a way to pass data through the component tree without having to pass props
 * down manually at every level.
 *
 * The context is used as follows:
 * - A Provider component (usually at the top level of the app) provides the Firebase instance
 *   as a value to all the components in its tree.
 * - Any component in the tree can then access the Firebase instance using the useContext hook.
 */
interface FirebaseContextType {
  app: FirebaseApp;
  db: Firestore;
}

const FirebaseContext: Context<FirebaseContextType | null> =
  createContext<FirebaseContextType | null>(null);

export default FirebaseContext;

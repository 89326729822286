import { createContext } from "react";

/**
 * UserContext is a context created for managing the global state related to the current user.
 * The context is part of the Context API in React. It allows us to avoid prop drilling
 * by providing a way to pass data through the component tree without having to pass props
 * down manually at every level.
 *
 * The UserContext is used as follows:
 * - A Provider component (usually at the top level of the app) provides the user object
 *   (which represents the current logged-in user) as a value to all the components in its tree.
 * - Any component in the tree can then access the user object using the useContext hook.
 *
 * This context is particularly useful for components that need to know about the user
 * (e.g., to render user-specific information or controls), but are deeply nested within the
 * component hierarchy and would otherwise need the user data to be passed down through many levels.
 */
const UserContext = createContext(null);
export default UserContext;

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDWfiXj2zQ4inozpFfMFeoRfNeU_eNJ0B4",
  authDomain: "eimr-3b02f.firebaseapp.com",
  projectId: "eimr-3b02f",
  storageBucket: "eimr-3b02f.appspot.com",
  messagingSenderId: "31255411064",
  appId: "1:31255411064:web:b9e4069b36d5868861308d",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { app, auth, db };

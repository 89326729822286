import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";

/**
 * Custom hook to listen for Firebase authentication changes.
 * It sets the authenticated user in local storage and state.
 * The user is retrieved from local storage when the component mounts.
 *
 * @returns {Object} The authenticated user object.
 */
export default function useAuthListener() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("authUser"))
  );

  const auth = getAuth();

  useEffect(() => {
    const listener = onAuthStateChanged(auth, (authUser) => {
      // If there's an authenticated user, store it in local storage and state
      if (authUser) {
        const authUserJson = JSON.stringify(authUser);
        localStorage.setItem("authUser", authUserJson);
        setUser(authUser);
      } else {
        // If there's no authenticated user, remove it from local storage and state
        localStorage.removeItem("authUser");
        setUser(null);
      }
    });

    // Clean up the listener on unmount
    return () => listener();
  }, [auth]);

  return { user };
}

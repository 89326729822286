import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "../constants/routes";

export default function ProtectedRoute({ user, children }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate(ROUTES.LOGIN, { replace: true });
    }
  }, [user, navigate]); // Re-run this effect when 'user' or 'navigate' changes

  // If user exists, render children, otherwise render null
  return user ? children : null;
}
